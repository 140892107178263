@use "../config/" as *;

.waf-showcase {
    --_listing-line: 4;
    --_listing-title: 20;
    @extend %p-8-4;
    @extend %neutral-50-bg;
    .waf-head {
        @extend %flex;
        @extend %mb-5;
        .title {
            @extend %secondary-800;
        }
    }
    .article {
        &-item {
            @extend %half-radius;
            @extend %pure-white-900-bg;
        }
        &-content {
            @extend %p-4;
        }
        &-title {
            @extend %mb-8;
            @extend %neutral-800;
        }
        &-description {
            @include truncate-text(4);
            @extend %font-14-pm;
            @extend %mb-8;
            @extend %neutral-600;
            p {
                line-height: 2rem;
                @extend %font-14-pm;
                @extend %neutral-600;
            }
        }
        &-meta {
            .meta {
                @extend %font-12-pm;
                @extend %neutral-500;
            }
            .meta-category  {
                left: var(--space-4);
                top: var(--space-3);
                @extend %secondary-800;
            }
        }
    }
    .loadmore-wrap,.pagination-wrap,.head-tab,.btn-article {
        @extend %d-none;
    }
}
.waf-listing {
    @extend %p-8-4;
    @extend %neutral-50-bg;
    @include listing-card(horizontal,11rem);
    .waf-head {
        @extend %mb-5;
    }
    .head-tab,.loadmore-wrap,.pagination-wrap,.tagline {
        @extend %d-none;
    }
    .article {
        &-list {
            @extend %gap-7;
            @extend %flex-column;
        }
        &-item {
            border-radius: 0;
        }
        &-content {
            padding: 0 0 0 var(--space-4);
            position: relative;
        }
        &-description {
            @extend %d-none;
        }
        &-title {
            @extend %neutral-800;
        }
        &-meta {
            margin-bottom: 0;
            .meta-category {
                left: var(--space-4);
            }
        }
    }
    .img-box {
        @extend %h-100;
    }
}
.wcm-info {
    @extend %p-8-4;
    @extend %pure-white-900-bg;
    .waf-head {
        @extend %mb-5;
    }
    .waf-body {
        padding-block: var(--space-6) var(--space-13);
        @extend %half-radius;
        @extend %px-4;
        @extend %neutral-50-bg;
    }
    .wcm-info-title {
        @extend %mb-6;
        @extend %neutral-800;
        @extend %font-20-pb;
    }
    .wcm-info-text {
        height: 24rem;
        overflow: hidden;
        .text,li,p {
            line-height: 2rem;
            @extend %mb-1;
            @extend %neutral-800;
            @extend %font-14-pr;
        }
        ul {
            list-style: disc;
            @extend %pl-5;
        }
    }
    .wcm-info-content {
        @extend %relative;
    }
    .btn {
        height: 3.2rem;
        @include position(null,null,var(--space-10-neg),0);
        @extend %pos-bl;
        @extend %px-7;
        @extend %half-radius;
        @extend %flex-n-c;
        @extend %primary-300-bg;
        &-text {
            @extend %font-12-pm;
            @extend %secondary-800;
            @extend %uppercase;
        }
        &-less {
            @extend %d-none;
        }
        &[aria-expanded=true]  {
            .btn-less {
                @extend %flex;
            }
            .btn-more {
                @extend %d-none;
            }
            & ~ .wcm-info-text {
                height: auto;
            }
        }
        &.btn-subscribe {
            left: 15rem;
        }
    }
    &-img {
        @extend %my-4;
    }
}
.wcm-contributor {
    @extend %p-8-4;
    @extend %secondary-50-bg;
    .waf-head {
        @extend %mb-5;
    }
    .contributors {
        &-list {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-item {
            border: .2rem solid var(--neutral-50);
            @extend %gap-4;
            @extend %flex;
            @extend %p-4;
            @extend %full-radius;
            @extend %pure-white-900-bg;
        }
        &-thumbnail {
            width: 8.1rem;
            height: 8.1rem;
            border: .3rem solid var(--neutral-100);
            overflow: hidden;
            flex-shrink: 0;
            @extend %circle-radius;
            .img-box {
                @extend %secondary-50-bg;
            }
        }
        &-title {
            @extend %pb-4;
            @extend %neutral-900;
            @extend %capitalize;
            @extend %font-16-pb;
        }
        &-text {
            @extend %neutral-500;
            @extend %font-12-pm;
            @include truncate(3,12,18,null);
        }
    }
}
.wcm-poll {
    @extend %neutral-50-bg;
    &-banner {
        @extend %p-8-4;
    }
    &-redirection {
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
        @extend %p-8-4;        
    }
    &-btn {
        @extend %gap-4;
        @extend %flex-column;
    }
    .wcm-question {
        @extend %mb-5;
    }
    .title {
        @extend %pure-white-900;
        @extend %font-20-pb;
        @extend %mb-2;
    }
    .sub-title {
        @extend %pure-white-900;
        @extend %font-18-pb;
    }
    .btn {
        cursor: pointer;
        @extend %rounded-radius;
        @extend %py-3;
        @extend %gap-3;
        @extend %flex-c-c;
        @extend %pure-white-900-bg;
        &:after {
            @include icon(drop-arrow);
        }
        &-text {
            @extend %neutral-900;
            @extend %capitalize;
            @extend %font-16-pb;
        }
    }
}
.wcm-article {
    @extend %p-8-4;
    @extend %pure-white-900-bg;
    &-title {
        @extend %mb-7;
        @extend %neutral-800;
        @extend %font-24-pb;
    }
    &-image {
        @extend %pb-4;
    }
    &-action {
        @extend %gap-4;
        @extend %flex-column;
    }
    .btn {
        cursor: pointer;
        @extend %py-2;
        @extend %half-radius;
        @extend %flex-c-c;
        &-text {
            @extend %secondary-800;
            @extend %uppercase;
            @extend %font-12-pm;
        }
        &-subscribe {
            @extend %primary-300-bg;
        }
        &-more {
            min-height: 3rem;
            @include btn-animation;
            width: 100%;
            &::before {
                content: unset;
            }
        }
    }
    .text {
        line-height: 2rem;
        @extend %mb-6;
        @extend %font-14-pr;
    }
}
@include mq(col-md) {
    .waf-showcase {
        padding-inline: 0;
        .waf-head .title {
            font-size: 2.4rem;
        }
        .article {
            &-list {
                display: unset;
            }
        }
    }
    .waf-listing {
        padding-inline: 0;
        @include listing-card(vertical);
        .waf-head .title {
            font-size: 2.4rem;
        }
        .article {
            &-list {
                flex-direction: row;
                @include card-count(2, var(--space-7),true);
            }
            &-wrap {
                height: 100%;
            }
            &-item {
                background: var(--pure-white-900);
                border-radius: var(--half-radius);
            }
            &-content {
                padding: var(--space-4);
            }
            &-meta {
                .meta-category {
                    top: var(--space-3);
                }
            }
        }
    }
    .wcm-info {
        padding-inline: 0;
        &-img {
            margin-inline: auto;
        }
        .waf-head .title {
            font-size: 2.4rem;
        }
        .wcm-info-text {
            height: 10rem;
            .text,li,p {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }
    .wcm-contributor {
        padding-inline: 0;
        .waf-head .title {
            font-size: 2.4rem;
        }
        .contributors {
            &-list {
                flex-direction: row;
                @include card-count(2, var(--space-4),true);
            }
            &-item {
                align-items: center;
                flex-direction: column;
                gap: var(--space-6);
            }
            &-thumbnail {
                width: 11rem;
                height: 11rem;
            }
            &-content {
                text-align: center;
            }
            &-title {
                font-size: 2rem;
            }
            &-text {
                font-size: 1.6rem;
                line-height: 2.2rem;
                height: auto;
            }
        }
    }
    .wcm-static {
        .layout-wrapper {
            max-width: var(--content-width);
        }
    }
    .wcm-poll {
        &-redirection,&-banner {
            padding-inline: var(--container-white-space);
        }
        &-btn {
            flex-direction: row;
            @include card-count(2, var(--space-4),true);
        }
        .btn-text {
            font-size: 1.8rem;
        }
        .title {
            font-size: 3.2rem;
            line-height: 3.8rem;
        }
        .sub-title {
            font-size: 2.6rem;
            line-height: 3rem;
        }
        .wcm-question {
            margin-bottom: var(--space-10);
        }
    }
    .wcm-article {
        padding-inline: var(--container-white-space);
        &-wrap {
            padding: var(--space-6) var(--space-4);
            box-shadow: 0 0 .4rem 0 clr(neutral-900, 1);
        }
        &-action {
            flex-direction: row;
        }
        .btn {
            width: max-content;
            &-subscribe {
                padding-inline: var(--space-4);
            }
            &-more {
                &::before {
                    content: '';
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-showcase {
        padding-block: 6rem;
        .article {
            &-wrap {
                flex-direction: row;
            }
            &-content {
                width: 45%;
                padding-block: var(--space-10);
            }
            &-thumbnail {
                width: 55%;
                .img-box {
                    border-radius: var(--half-radius) 0 0 var(--half-radius);
                }
            }
            &-description {
                display: unset;
            }
            &-meta {
                .meta-category  {
                    top: var(--space-10);
                }
            }
            &-title {
                --_listing-line: 3;
                margin-bottom: 0;
            }
        }
    }
    .waf-listing {
        padding-block: 6rem;
        .article {
            &-list {
                @include card-count(4, var(--space-6), true, true);
            }
            &-title {
                margin-bottom: var(--space-2);
            }
        }
    }
    .wcm-info {
        padding-block: 6rem;
        &-img {
            width: 50%;
        }
        .waf-body {
            padding: var(--space-6) var(--space-6) 6rem;
        }
    }
    .wcm-contributor {
        padding-block: 6rem;
        .contributors-list {
            @include card-count(4, var(--space-4),true,true);
        }
    }
    .wcm-banner {
        .image  {
            aspect-ratio: 1356 / 450;
        }
    }
    .wcm-poll {
        padding: var(--space-15) var(--container-white-space);
        &-wrap {
            display: flex;
            gap: var(--space-4);
        }
        &-redirection {
            padding: var(--space-15);
            border-radius: var(--half-radius);
            width: 70%;
            @include flex-config(flex,column,center);
        }
        &-banner {
            padding: 0;
            width: 30%;
            .image {
                height: 100%;
            }
        }
    }
    .wcm-article {
        &-wrap {
            display: flex;
        }
        &-thumbnail {
            width: 30rem;
        }
        &-content {
            width: calc(100% - 30rem);
            padding-left: var(--space-4);
        }
        &-image {
            height: 60rem;
            img {
                height: 100%;
                object-fit: contain;
            }
        }
        .text {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }
}